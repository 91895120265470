import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { gsap } from 'gsap'
import * as dat from 'lil-gui'


/**
 * Loaders
 */
const loadingBarWrapper = document.querySelector('.loading-wrap')
const loadingBarElement = document.querySelector('.loading-bar')
const contentText = document.querySelector('p')
const contentBtns = document.querySelectorAll('button')

let sceneReady = false
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 2, value: 0})
            gsap.from(contentText, {autoAlpha: 0, y: 30, duration: 1})
            gsap.from(contentBtns, {autoAlpha: 0, y: 30,stagger: 0.5, duration: 1})


            // Update loadingBarElement
            loadingBarWrapper.classList.add('ended')
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)

        window.setTimeout(() =>
        {
            sceneReady = true
        }, 2000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)
const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
const objlLoader = new OBJLoader(loadingManager);

/**
 * Base
 */

// Debug
const gui = new dat.GUI()
gui.hide()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 },
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})

const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/6/px.png',
    '/textures/environmentMaps/6/nx.png',
    '/textures/environmentMaps/6/py.png',
    '/textures/environmentMaps/6/ny.png',
    '/textures/environmentMaps/6/pz.png',
    '/textures/environmentMaps/6/nz.png'
])
environmentMap.encoding = THREE.sRGBEncoding


scene.background = environmentMap
scene.environment = environmentMap

debugObject.envMapIntensity = 4



/**
 * Models
 */
let mixer = null
let animation = {} 
gltfLoader.load(
    /* '/models/Astro/astronauta.glb', */
    '/models/Helly/helly.glb',
    (gltf) =>
    {
        //gltf.scene.scale.set(2, 2, 2)
        //gltf.scene.rotation.y = Math.PI * 1
        //gltf.scene.rotation.z = Math.PI * 0.5
        gltf.scene.position.y = 0.1
        //gui.add(gltf.scene.position, 'y').min(- 5).max(5).step(0.05)
        scene.add(gltf.scene)

        // Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        console.log(gltf.animations);
        // Actions
        animation.actions = {}
        animation.actions.hello = mixer.clipAction(gltf.animations[0])
        animation.actions.idle = mixer.clipAction(gltf.animations[1])
        animation.actions.speak = mixer.clipAction(gltf.animations[2])
        animation.actions.thank = mixer.clipAction(gltf.animations[3])
        animation.actions.think = mixer.clipAction(gltf.animations[4])
        animation.actions.wait = mixer.clipAction(gltf.animations[5])

        animation.actions.current = animation.actions.wait
        animation.actions.current.play() 

        // Play the action
        animation.play = (name) =>
        {
            const newAction = animation.actions[name]
            const oldAction = animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 0.6)

            animation.actions.current = newAction
        }

        updateAllMaterials()
    }
)



var wallMaterial = new THREE.MeshBasicMaterial({color: 0x000000,});
var wallGeometry = new THREE.BoxGeometry(10, 5, 0.2);
var wall = new THREE.Mesh(wallGeometry, wallMaterial);
wall.position.set(0.5, 1, -1)
scene.add(wall);



/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(- 1.5, 1.5, 1)
//scene.add(directionalLight)

const pointLight = new THREE.PointLight( 0x0ff000, 1, 100 );
pointLight.position.set( 50, 50, 50 );
scene.add( pointLight );
/* gui.add(pointLight.position, 'x').min(- 5).max(5).step(0.05)
gui.add(pointLight.position, 'y').min(- 5).max(5).step(0.05)
gui.add(pointLight.position, 'z').min(- 5).max(5).step(0.05) */

/**
 * Sizes
 */
function vh(percent) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
}
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const mediaQuery = window.matchMedia('(min-width: 1024px)')
if (mediaQuery.matches) {
    sizes.height = window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
   /*  if (mediaQuery.matches) {
        sizes.height = vh(100)
    } else {
        sizes.height = vh(100)
    } */

    console.log("resize");

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 1.4, 1) 
camera.zoom = 4
scene.add(camera)
/* gui.add(camera.position, 'x').min(- 5).max(5).step(0.05)
gui.add(camera.position, 'y').min(- 5).max(5).step(0.05)
gui.add(camera.position, 'z').min(- 5).max(5).step(0.05) */


// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 1.2, 0) 
controls.enableDamping = true
controls.enabled = true;
/* gui.add(controls.target, 'x').min(- 5).max(5).step(0.05)
gui.add(controls.target, 'y').min(- 5).max(5).step(0.05)
gui.add(controls.target, 'z').min(- 5).max(5).step(0.05)

 */
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Model animation
    if(mixer)
    {
        mixer.update(deltaTime)
    }

    // Update controls
    controls.update()

    
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


document.addEventListener("animation", function(event) {
    //floor.material.color.setHex( event.detail.color );


    if(event.detail.repeation > 0){

        animation.play(event.detail.animation)

        setTimeout( function() {
            animation.play(event.detail.callback)
        }, ((animation.actions[event.detail.animation]._clip.duration)*1000));

        setTimeout( function() {
            animation.play("idle")
        }, event.detail.duration );

    } else {
        animation.play(event.detail.animation)

        if(event.detail.animation != "wait"){
            setTimeout( function() {
                animation.play("idle")
            }, event.detail.duration);
        }

        

    }
    
});

  



